.venue-hire-page {
  .calender-container {
    .nice-dates-navigation_current {
      font-family: var(--font-serif);
      font-size: 46px;
    }

    .selected-dates {
      color: var(--grey-1);
    }

    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  form {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    input,
    textarea {
      border: 1px solid #e0e0e0;
      padding: 26px 31px;
      font-size: 10px;
      color: black;

      &:focus {
        outline: none;
      }
    }

    textarea {
      height: 200px;
      resize: none;
    }

    button {
      align-self: flex-start;
      background-color: #fbfbfb;
      padding: 20px;
      border: none;
      letter-spacing: 0.18em;
      font-size: 12px;
    }
  }
}
