.visitor-info-page {
  h1 {
    font-size: 46px;
    font-family: var(--font-serif);
    margin-bottom: 70px;
    text-align: center;
    margin-top: 50px;
  }

  .table {
    width: min(90vw, 675px);
    margin: 0 auto;

    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;

    row-gap: 16px;
    column-gap: 5vw;
  }

  .line {
    flex: 1;
    height: 1px;
    background-color: #e0e0e0;
  }
}
