.gallery-3d-page {
  .gallery {
    display: flex;
    gap: 20px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e0e0e0;

    @media only screen and (max-width: 750px) {
      flex-direction: column;
    }

    .image-container {
      min-width: 270px;
      min-height: 180px;
      position: relative;
    }

    .body {
      flex: 1;
    }

    .content {
      margin-top: 16px;
      max-width: 495px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
