.loader {
  width: 100%;
  height: 100%;

  display: grid;
  place-items: center;

  background-color: var(--black);
  color: white;
  font-size: 36px;
  font-family: var(--font-display-53);
}
