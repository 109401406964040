footer {
  width: 100%;
  padding: 90px 0;
  background-color: var(--grey-2);

  .wrapper {

    .logo
    {
      width: 120px;
    }

    display: grid;
    grid-template-columns: 1fr repeat(4, max-content);
    column-gap: 5vw;
    width: min(90vw, 1200px);
    margin: 0 auto;
    color: white;

    .footer-heading {
      font-family: var(--font-serif);
      font-size: 26px;
      margin-bottom: 18px;
    }

    a,
    .social-link {
      display: block;
      margin-bottom: 10px;
      font-size: 12px;
      text-transform: uppercase;
    }

    @media only screen and (max-width: 830px) {
      grid-template-columns: unset;
      row-gap: 40px;
    }
  }
}
