.press-releases-page {
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media only screen and (max-width: 650px) {
      grid-template-columns: unset;
    }

    .card {
      border: 1px solid #e0e0e0;
      padding: 16px 35px;

      h3 {
        font-family: var(--font-serif);
        font-size: 26px;
      }
    }
  }
}
