.page-heading {
  display: flex;
  align-items: center;
  gap: 22px;
  margin-bottom: 67px;

  .line {
    flex: 1;
    height: 1px;
    background-color: #e0e0e0;
  }
}
