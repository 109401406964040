.header {
  width: 100%;
  z-index: 99;

  .wrapper {
    width: min(90vw, 1140px);
    margin: 0 auto;
    padding: 30px 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-container {
      z-index: 10;
    }

    .logo
    {
      width: 100px;
    }
    nav.desktop-nav {
      display: flex;
      gap: 40px;
      font-size: 12px;
      position: relative;
      @media only screen and (max-width: 750px) {
        display: none;
      }

      .dropdown-button {
        display: flex;
        gap: 7px;
        align-items: center;
        color: inherit;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }

      .dropdown {
        position: absolute;
        top: 30px;
        background-color: #fff;
        color: var(--grey-1);
        min-width: 100px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border: 1px solid rgb(223, 223, 223);
      }
      svg {
        stroke: #929292;
      }

      div[role="menu"] {
        visibility: hidden;
      }

      div[role="menu"].visible {
        visibility: visible;
      }
    }
    .menu-button {
      display: none;

      @media only screen and (max-width: 750px) {
        display: block;
      }
    }
  }

  .mobile-nav {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    color: var(--grey-1);

    .close-button {
      display: flex;
      justify-content: flex-end;
      margin: 80px 20px 0 0;
    }

    nav {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 150px;
      margin-left: 5vw;

      .main-link {
        font-family: var(--font-serif);
        font-size: 26px;
      }
    }
  }

  &.transparent {
    position: absolute;
    top: 0;

    color: white;

    nav.desktop-nav {
      svg {
        stroke: white;
      }
    }
  }
}
