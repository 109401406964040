.artist-listing-page {
  .heading {
    display: flex;
    align-items: center;
    gap: 22px;
    margin-bottom: 67px;

    .line {
      flex: 1;
      height: 1px;
      background-color: #e0e0e0;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    /*grid-template-columns: repeat(4, minmax(220px, 1fr));*/
    gap: 20px;
  }

  .view-more {
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .view-more:hover
  {
      cursor:pointer;
  }
}
