@font-face {
  font-family: "Cormorant";
  src: url("../assets/fonts/Cormorant-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Julius Sans One";
  src: url("../assets/fonts/JuliusSansOne-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3 {
  line-height: 1.2;
  font-weight: normal;
  color: var(--grey-1);
}

h1 {
  font-size: 46px;
}

h2 {
  font-size: 22px;
}

p {
  font-family: var(--font-serif);
  color: var(--grey-2);
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2;
}

.f-10 {
  font-size: 10px;
}
.f-12 {
  font-size: 12px;
}
.f-14 {
  font-size: 14px;
}
.f-16 {
  font-size: 16px;
}
.f-18 {
  font-size: 18px;
}
.f-26 {
  font-size: 26px;
}

.serif {
  font-family: var(--font-serif);
}
.sans {
  font-family: var(--font-sans-serif);
}

.caps {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.link {
  font-size: 10px;
  letter-spacing: 0.18em;
}
