.artwork-card {
  display: flex;
  flex-direction: column;
  gap: 13px;
  cursor: pointer;
  h3 {
    font-family: var(--font-serif);
    font-size: 26px;
  }

  .link {
    color: var(--grey-3);
  }
}

.artwork-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  z-index: 100;
  overflow-y: auto;
  .wrapper {
    width: min(90vw, 1140px);
    margin: 0 auto;
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media only screen and (max-width: 700px) {
      padding: 30px 0;
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        cursor: pointer;
        width: 50px;
      }

      @media only screen and (max-width: 700px) {
        h1 {
          font-size: 26px;
        }
      }
    }

    .meta {
      display: flex;
      gap: 40px;
    }
  }
}
