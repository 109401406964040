.contact-page {
  form {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    input,
    textarea {
      border: 1px solid #e0e0e0;
      padding: 26px 31px;
      font-size: 10px;
      color: black;

      &:focus {
        outline: none;
      }
    }

    textarea {
      height: 200px;
      resize: none;
    }

    button {
      align-self: flex-start;
      background-color: #fbfbfb;
      padding: 20px;
      border: none;
      letter-spacing: 0.18em;
      font-size: 12px;
    }
  }

  .contact-info {
    margin-top: 80px;
    text-align: center;
  }

  .map-container {
    position: relative;
    height: min(60vh, 500px);
  }
}
