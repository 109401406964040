*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-family: var(--font-sans-serif);
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

a {
  color: inherit;
  text-decoration: none;

  &:active,
  &:visited {
    color: inherit;
    text-decoration: none;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f2f2f2;
}

*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #f2f2f2;
}
*::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 20px;
  border: 3px solid #f2f2f2;
}
