.hero {
  position: relative;
  width: 100%;
  height: 100vh;

  display: grid;
  place-items: center;

  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      background-color: grey;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .wrapper {
    width: min(90vw, 465px);
    text-align: center;

    & > * {
      color: white;
    }
  }
}
