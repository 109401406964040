.about-page {
  .text-wrapper {
    margin-top: -60px;
    margin: 50px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      margin-bottom: 30px;
      font-family: var(--font-serif);
      font-size: 46px;
      color: var(--grey-1);
      max-width: 764px;
    }
    p {
      max-width: 560px;
      margin-bottom: 30px;
    }
  }

  .mission-wrapper {
    width: min(90vw, 718px);
    margin: 20px auto;
    padding: 65px 5vw;
    background-color: var(--grey-2);
    text-align: center;

    h2 {
      margin-bottom: 30px;
      color: white;
    }
    p {
      color: white;
    }
  }
}
