.virtual-gallery-page {
  .text-wrapper {
    margin: 50px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-bottom: 30px;
      font-family: var(--font-serif);
      font-size: 46px;
      color: var(--grey-1);
      max-width: 764px;
    }

    p {
      max-width: 560px;
    }
  }

  .center-text {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    text-align: center;
    z-index: -1;
  }
}
