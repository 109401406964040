.blog-listing-page {
  .featured-post {
    margin-top: -60px;
    .text-wrapper {
      margin: 50px auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        margin-bottom: 30px;
        font-family: var(--font-serif);
        font-size: 46px;
        color: var(--grey-1);
        max-width: 764px;
      }
      p {
        max-width: 560px;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    column-gap: 20px;
    row-gap: 43px;
    margin-top: 95px;
  }

  .post {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .image-container {
      position: relative;
      height: 180px;
      width: 100%;
    }

    h3 {
      font-family: var(--font-serif);
      font-size: 26px;
    }
  }
}
