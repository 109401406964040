.blog-post-page {
  h1 {
    max-width: 764px;
  }
  .meta-data {
    font-size: 10px;
    letter-spacing: 0.18em;
    display: flex;
    gap: 40px;
    margin: 26px 0 37px 0;
    .author {
      color: var(--grey-1);
    }
    .date {
      color: var(--grey-3);
    }
  }

  .post-body {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    gap: 100px;

    @media only screen and (max-width: 750px) {
      flex-direction: column;
    }
    .content {
      width: min(90vw, 705px);
      p {
        font-size: 22px;
      }

      figcaption {
        margin-top: -34px;
        font-size: 16px;
        display: block;
        text-align-last: left;
        font-family: var(--font-serif);
      }

      & > * {
        margin-bottom: 40px;
      }
    }

    .artist-related {
      max-width: 270px;

      & > * {
        margin-bottom: 30px;
      }

      .artist-card {
        height: 180px;
      }

      @media only screen and (max-width: 750px) {
        max-width: unset;
      }
    }
  }

  .related-articles {
    margin-top: 100px;
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      column-gap: 20px;
      row-gap: 43px;
      margin-top: 35px;
    }

    .post {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .image-container {
        position: relative;
        height: 180px;
        width: 100%;
      }

      h3 {
        font-family: var(--font-serif);
        font-size: 26px;
      }
    }
  }
}
