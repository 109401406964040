.home-page {

    .white-overlay
    {
        padding: 22px 20px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 13.05%, rgba(0, 0, 0, 0.1) 100%);
    }

    .white-overlay:hover
    {

        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 13.05%, rgba(0, 0, 0, 0) 100%);
    }

  .featured-events {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

    .event {
      height: 320px;
      position: relative;
      /*padding: 22px 20px;*/

      display: flex;
      flex-direction: column;
      gap: 10px;

      color: white !important;
    }

    @media only screen and (max-width: 650px) {
      flex-direction: column;
    }
  }

  .featured-artists {
    margin-top: 90px;

    .discover {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: 20px;
      margin-top: 40px;
    }
  }

  .permanent-collection {
    margin-top: 130px;

    .text-wrapper {
      width: min(90vw, 465px);
      margin: 50px auto;
      text-align: center;

      h2 {
        margin-bottom: 30px;
      }

      .view-collection {
        margin-top: 50px;
      }
    }
  }
}
