.artist-card {
  position: relative;
  display:block;
  height: 357px;

  .name
  {
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: 20px;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%,0);
      color: white;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
      text-transform: uppercase;
      font-size: 20px;
      font-family: var(--font-serif);
  }

  
}

.overlay
  {
      padding: 20px 20px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 13.05%, rgba(0, 0, 0, 0.7) 100%);
  }

  .overlay:hover
  {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 13.05%, rgba(0, 0, 0, 0) 100%);
  }