.event-card {
  display: flex;
  flex-direction: column;
  gap: 13px;
  .image-container {
    position: relative;
    height: 180px;
    width: 100%;
  }

  h3 {
    font-family: var(--font-serif);
    font-size: 26px;
  }
}
