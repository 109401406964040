.mt-auto {
  margin-top: auto;
}

.mt-50 {
  margin-top: 50px;
}

.c-white {
  color: white;
}

.bg-image {
  background-color: grey;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.space-between {
  display: flex;
  justify-content: space-between;
}
