.modal.d-block {
    background-color: rgba(0, 0, 0, 0.2);
    overflow: scroll !important;
}
.d-block {
    display: block !important;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-container {
    background-color: white;
    margin-top: 50px;
    width: 80%;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 2px 0px #7c7c7c;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.modal-container .modal-content- {
    position: relative;
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.modal-container .modal-content- .modal-close {
    position: absolute;
    right: 0;
    top: 0;
}
.inex-icon, .close-icon {
    font-size: 12px;
    color: #8D8D9E;
}

.closeBtn
{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor:pointer;
}
