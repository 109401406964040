.organisation-page {
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
    margin-top: 40px;

    .card {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .image-container {
        position: relative;
        height: 180px;
        width: 100%;
      }

      h3 {
        font-family: var(--font-serif);
        font-size: 26px;
      }
    }
  }
}
