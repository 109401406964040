.event-listing-page {
  
  h2
  {
    margin-bottom:30px;
  }
  .cover-image {
    width: 100%;
    height: 40vh;
    position: relative;
  }

  .filters {
    display: flex;
    gap: 40px;
    margin: 22px 0 30px 0;
    color: var(--grey-3);
    font-size: 10px;
    letter-spacing: 0.18em;
    cursor:pointer;
    .active {
      color: var(--grey-1);
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;
    }
  }

  .event {
    display: flex;
    gap: 20px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e0e0e0;

    @media only screen and (max-width: 750px) {
      flex-direction: column;
    }

    .image-container {
      min-width: 270px;
      min-height: 180px;
      position: relative;
    }

    .body {
      flex: 1;
      .wrapper {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        position: relative;

        @media only screen and (max-width: 750px) {
          flex-direction: column;
        }
      }
    }

    .content {
      max-width: 495px;
      h3 {
        margin-bottom: 16px;
      }
    }

    .meta
    {
       position: absolute;
       right: 0;

       .dates
       {
          position: relative;

          width: 150px;

          .day {
            font-family: var(--font-serif);
            font-size: 56px;
            line-height: 0.2;
          }

          .week-day,
          .month {
            font-size: 10px;
            color: var(--grey-3);
            position: absolute;
            left: 60px;
          }
          .week-day
          {
            top:0;
          }
          .month {
            color: var(--grey-1);
            top: 15px;
          }
       }
    }
  }
}

form {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    input,
    textarea {
      border: 1px solid #e0e0e0;
      padding: 26px 31px;
      font-size: 10px;
      color: black;

      &:focus {
        outline: none;
      }
    }

    .email
    {
      text-transform:none !important;
    }

    textarea {
      height: 200px;
      resize: none;
    }

    button {
      align-self: flex-start;
      background-color: #fbfbfb;
      padding: 20px;
      border: none;
      letter-spacing: 0.18em;
      font-size: 12px;
    }
  }