.gallery-page {
  .featured-item {
    margin-top: -60px;
    .text-wrapper {
      margin: 50px auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        margin-bottom: 30px;
        font-family: var(--font-serif);
        font-size: 46px;
        color: var(--grey-1);
        max-width: 764px;
      }
      p {
        max-width: 560px;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
    margin-top: 40px;

    .artwork-card {
      display: flex;
      flex-direction: column;
      gap: 13px;

      h3 {
        font-family: var(--font-serif);
        font-size: 26px;
      }

      .link {
        color: var(--grey-3);
      }
    }
  }
}
