.artist-page {
  .overview {
    display: flex;
    gap: 7vw;
    margin-bottom: 100px;
    @media only screen and (max-width: 800px) {
      flex-direction: column-reverse;
    }

    .featured-image {
      flex: 1;
    }

    .about {
      flex: 1;

      .social-links {
        display: flex;
        gap: 40px;
        padding: 16px 0;
        margin: 15px 0 19px 0;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;

        font-size: 10px;
        letter-spacing: 0.18em;
        color: var(--grey-3);
      }

      p {
        margin-bottom: 40px;
        font-size: 22px;
        line-height: 1.4;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
    margin-top: 40px;

    .artwork-card {
      display: flex;
      flex-direction: column;
      gap: 13px;

      h3 {
        font-family: var(--font-serif);
        font-size: 26px;
      }

      .link {
        color: var(--grey-3);
      }
    }
  }
}
