.event-page {
  .gallery-section {
  

    .gallery {
      flex: 1;
      height: 80px;
      position: relative;
      width: 100%;

      .view-button {

        align-items: center;
        background-color: rgba(0,0,0);
        font-size: 15px;
        color: white;
        position: absolute;
         top: 50%;
         margin-right: -50%;
         left: 50%;
         transform:translate(-50%,-50%);
         padding: 50px;
         width: 100%;
         text-align:center;

         .content
         {
            position: relative;
            .title
            {
              margin-right: 20px;
            }

            .icon
            {
                position: absolute;
                top: 0px;
            }
         }

         .icon
         {

         }
      }

      .view-button:hover
      {
        background-color: rgba(0,0,0,0.5);
        border: 1px solid gray;
      }
    }

    @media only screen and (max-width: 650px) {
      flex-direction: column;
    }
  }

  .featured-artists {
    margin-top: 90px;
  }

  .discover {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .grid {
    display: grid;
    /*grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));*/
    grid-template-columns: repeat(4, minmax(220px, 1fr));
    gap: 20px;
    margin-top: 40px;
  }

  .events {
    margin-top: 90px;
  }

  .past-events {
    background-color: #fbfbfb;
    width: 100%;
    padding: 70px 0 100px 0;

    img {
      z-index: 1;
    }
    .wrapper {
      width: min(90vw, 1140px);
      margin: 0 auto;
    }
  }
}

.gallery-page {
  .featured-item {
    margin-top: -60px;
    .text-wrapper {
      margin: 50px auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        margin-bottom: 30px;
        font-family: var(--font-serif);
        font-size: 46px;
        color: var(--grey-1);
        max-width: 764px;
      }
      p {
        max-width: 560px;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
    margin-top: 40px;

    .artwork-card {
      display: flex;
      flex-direction: column;
      gap: 13px;

      h3 {
        font-family: var(--font-serif);
        font-size: 26px;
      }

      .link {
        color: var(--grey-3);
      }
    }
  }
}
