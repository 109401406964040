.jobs-page {
    
    .filters {
    display: flex;
    gap: 40px;
    margin: 22px 0 30px 0;
    color: var(--grey-3);
    font-size: 10px;
    letter-spacing: 0.18em;
    cursor:pointer;
    .active {
      color: var(--grey-1);
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media only screen and (max-width: 650px) {
      grid-template-columns: unset;
    }

    .card {
      border: 1px solid #e0e0e0;
      padding: 16px 35px;

      .link {
        margin-bottom: 10px;
      }
      h3 {
        font-family: var(--font-serif);
        font-size: 26px;
      }
    }
  }
}
